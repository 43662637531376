import React, { Component } from "react";
import window from 'global';
import '../styles/share.scss';
import ReactDOMServer from 'react-dom/server';
//import PropTypes from 'prop-types';
import Helmet from "react-helmet";
import Paper from '@material-ui/core/Paper';
import { css } from "@emotion/core"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout.js"
import SEO from "../components/seo"
import Img from "gatsby-image"
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Icon from '@material-ui/core/Icon';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import Navlinks from '../components/nav';
import { Offline, Online } from 'react-detect-offline';
import '../styles/ProdTemplate.scss';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';
import ImagePicker from 'react-image-picker';
import 'react-image-picker/dist/index.css'
import { AuthUserContext } from '../components/Session';
import * as ROUTES from '../constants/routes';
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

var webURL = "https://saltsmartapp.com";

const images = {
  locations: []
};
const carouselSlidesData = [];
//class LocationCarousel extends Carousel {}

 const slidersettings = {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1,
            infinite: true,
            
          }
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1,
            initialSlide: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1,
          }
        }
      ]
  };


class CarouselLeftArrow extends Component {
    render() {
      return (
        <a
          href="#"
          className="carousel__arrow carousel__arrow--left"
          onClick={this.props.onClick}
        >
          <span className="fa fa-2x fa-angle-left"/>
        </a>
      );
    }
  }
  
  class CarouselRightArrow extends Component {
    render() {
      return (
        <a
          href="#"
          className="carousel__arrow carousel__arrow--right"
          onClick={this.props.onClick}
        >
          <span className="fa fa-2x fa-angle-right"/>
        </a>
      );
    }
  }
  
  class CarouselIndicator extends Component {
    render() {
      return (
        <li>
          <a
            className={
              this.props.index == this.props.activeIndex
                ? "carousel__indicator carousel__indicator--active"
                : "carousel__indicator"
            }
            onClick={this.props.onClick}
          />
        </li>
      );
    }
  }
  
  class CarouselSlide extends Component {
    render() {
      return (
        <li
          className={
            this.props.index == this.props.activeIndex
              ? "carousel__slide carousel__slide--active"
              : "carousel__slide"
          }
        >
          <div className="video">
            <div className="videoInfo">
              <h3 className='videoTitle' dangerouslySetInnerHTML={{ __html: this.props.slide.title}}/>
            </div>
              <video 
                className="galleryVideo"
                src={this.props.slide.url}
                title={this.props.slide.title}
                poster={this.props.slide.poster}
                controls={true}
                playsinline
                webkit-playsinline
                crossOrigin="anonymous"
                type="video/mp4"
                frameBorder="0"
                webkitallowfullscreen="true"
                mozallowfullscreen="true"
                preload="auto"
              >
              </video>
          </div>
          <Online>
            <Link>
              <button value={'shareDialogV'+this.props.index} id={'deviceShareV'+this.props.index} type="button" className="icon-share" title={'Share '+this.props.slide.title} data-file={webURL+this.props.slide.url} onClick={openShareDialog}/>            
            </Link>
            <div id={'shareDialogV'+this.props.index} className="share-dialog">
              <button value={'shareDialogV'+this.props.index} onClick={closeShareDialog} className="boxClose"></button>
              <div className="inner">
                <header>
                  <h3 className="dialog-title">Share {this.props.slide.title}</h3>
                </header>
                <button value={'shareDialogV'+this.props.index} className="close-button" onClick={closeShareDialog}></button>
                <div className="targets">
                  <a className="button" href={'mailto:?body='+webURL+this.props.slide.url}>
                    <svg>
                      <use href="#email"></use>
                    </svg>
                    <span>Email</span>
                  </a>
                </div>
              </div>
            </div>
          </Online>
          <Offline>
            <button className="icon-share disabled" />
          </Offline>
        </li>
      );
    }
  }
  
  // Carousel wrapper component
  class VideoCarousel extends Component {
    constructor(props) {
      super(props);
  
      this.goToSlide = this.goToSlide.bind(this);
      this.goToPrevSlide = this.goToPrevSlide.bind(this);
      this.goToNextSlide = this.goToNextSlide.bind(this);
  
      this.state = {
        activeIndex: 0
      };
    }
  
    goToSlide(index) {
      this.setState({
        activeIndex: index
      });
      const galleryVideo = document.getElementsByClassName('galleryVideo');
      galleryVideo[index].pause()
    }
  
    goToPrevSlide(e) {
      e.preventDefault();
      
      let index = this.state.activeIndex;
      let { slides } = this.props;
      let slidesLength = slides.length;
      const galleryVideo = document.getElementsByClassName('galleryVideo');
      galleryVideo[index].pause()
      if (index < 1) {
        index = slidesLength;
      }
      
      --index;
  
      this.setState({
        activeIndex: index,
        
      });
      
    }
  
    goToNextSlide(e) {
      e.preventDefault();
  
      let index = this.state.activeIndex;
      let { slides } = this.props;
      let slidesLength = slides.length - 1;
      const galleryVideo = document.getElementsByClassName('galleryVideo');
      galleryVideo[index].pause()
      if (index === slidesLength) {
        index = -1;
      }
      
      ++index;
  
      this.setState({
        activeIndex: index
      });
      
    }
  
    render() {
      return (
        <div className={"carousel fCol12 c"+carouselSlidesData.length}>
          <CarouselLeftArrow onClick={e => this.goToPrevSlide(e)} />
  
          <ul className="carousel__slides">
            {this.props.slides.map((slide, index) =>
              <CarouselSlide
                key={index}
                index={index}
                activeIndex={this.state.activeIndex}
                slide={slide}
              />
              
            )}
          </ul>
  
          <CarouselRightArrow onClick={e => this.goToNextSlide(e)} />
  
          <ul className="carousel__indicators">
            {this.props.slides.map((slide, index) =>
              <CarouselIndicator
                key={index}
                index={index}
                activeIndex={this.state.activeIndex}
                isActive={this.state.activeIndex==index} 
                onClick={e => this.goToSlide(index)}
              />
            )}
          </ul>
        </div>
      );
    }
  }

  export class SelectGallery extends Component {
    constructor(props) {
      super(props)
      this.state = {
        image: null,
        images: []
      }
    }
  
    onPickImages(images) {
      this.setState({images})
    }
    render() {
      return (
        <div>
          <React.Fragment>
            <span id="shareGalIcon" className="icons inactive-shareICN">
              <div className="galleryShare">
                <button value={'shareDialogGal'} id={'deviceShareGal'} type="button"  title={'Share '} data-file={this.state.images.map((item) => webURL+item.src+`\n`+`\n`)} onClick={openShareDialogSelectImages}>Share Selected <span className="icon-share"> </span></button>            
                <button className="shareCancelCtrl" onClick={selectImageCollapse}>Cancel <span className="shareCancel"> </span></button>
              </div>
            </span>  
          </React.Fragment>
  
          <React.Fragment>
            <ImagePicker 
              images={images.map((image, i) => ({src: image.lbImage, value: i}))}
              onPick={this.onPickImages.bind(this)}
              multiple
            />
            {/*<textarea rows="4" cols="100" value={this.state.images && JSON.stringify(this.state.images)} disabled/>*/}
  
            <div id={'shareDialogGal'} className="share-dialog">
              <button value={'shareDialogGal'} onClick={closeShareDialogSelectImages} className="boxClose"></button>
              <div className="inner">
                <header>
                  <h3 className="dialog-title">Share Images</h3>
                </header>
                <button value={'shareDialogGal'} className="close-button" onClick={closeShareDialogSelectImages}></button>
                <div className="targets">
                  <a className="button" href={('mailto:?subject=Check out these images&body=Check out these images:%0D%0A%0D%0A'+this.state.images.map((item) => webURL+item.src+'%0D%0A')).replace(/,/g, '')}>
                    <svg>
                      <use href="#email"></use>
                    </svg>
                    <span>Email</span>
                  </a>
                  
                  <div className="hide">
                  <ul>
                  {this.state.images.map((item, value) => (
                    <React.Fragment>
                    <li className={'product-carried-'+value}>{webURL+item.src}</li>                   
                    </React.Fragment>
                  ))}
                  </ul>
                  </div>
                </div>
              </div>
            </div>
          </React.Fragment>
        </div>
      );
    }
  }

  export class LightboxGallery extends Component {
    constructor(props) {
      super(props);
      this.state = {
        photoIndex: 0,
        isOpen: false,
      };
    }
    onPickImages(images) {
      this.setState({images})
    }
   
    render() {
      const { photoIndex, isOpen } = this.state;
      const { locationIndex, imageCount } = this.props;
      return (
        <div>
          {imageCount >= 3 && 
            <Slider key={'lc'+locationIndex} id={'lc'+locationIndex} {...slidersettings}>
              {images.locations[locationIndex].photos.map((image,index) => (
                  <React.Fragment>
                      <div className="locationGalleryItem">
                          <div className="image" css={css`background: url(${image.lbImage});`} onClick={() => this.setState({ isOpen: true, photoIndex: index })}/>
                      </div>
                  </React.Fragment>  
              ))}
            </Slider>
          }
          {imageCount <= 2 && 
            <div className="companyImageGalleryDisable">
              {images.locations[locationIndex].photos.map((image,index) => (
                <React.Fragment>
                  <div className="locationGalleryItem">
                    <div className="image" css={css`background: url(${image.lbImage});`} onClick={() => this.setState({ isOpen: true, photoIndex: index })}/>
                  </div>
                </React.Fragment>  
              ))}
            </div>
          }
          {isOpen && (
            <Lightbox
              imageCaption= {images.locations[locationIndex].photos[photoIndex].lbCaption}
              mainSrc={images.locations[locationIndex].photos[photoIndex].lbImage}
              nextSrc={images.locations[locationIndex].photos[(photoIndex + 1) % images.locations[locationIndex].photos.length].lbImage}
              prevSrc={images.locations[locationIndex].photos[(photoIndex + images.locations[locationIndex].photos.length - 1) % images.locations[locationIndex].photos.length].lbImage}
              onCloseRequest={() => this.setState({ isOpen: false })}
              onMovePrevRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + images.locations[locationIndex].photos.length - 1) % images.locations[locationIndex].photos.length,
                })
              }
              onMoveNextRequest={() =>
                this.setState({
                  photoIndex: (photoIndex + 1) % images.locations[locationIndex].photos.length,
                })
              }
            />
          )}
        </div>
      );
    }
  }
  function selectImageCollapse() {
    var element = document.getElementById("selectWrapperImage");
    var imgLite = document.getElementById("lightboxWrapper");
    var imgShareIcn = document.getElementById("shareGalIcon");
    if (element.classList.contains('active-selects')) {
      element.classList.remove('active-selects');
      element.classList.add('inactive-selects');
    } else {
      element.classList.add('active-selects');
      element.classList.remove('inactive-selects');
    }
    if (imgLite.classList.contains('active-selects')) {
      imgLite.classList.remove('active-selects');
      imgLite.classList.add('inactive-selects');
    } else {
      imgLite.classList.add('active-selects');
      imgLite.classList.remove('inactive-selects');
    }
    if (imgShareIcn.classList.contains('active-shareICN')) {
      imgShareIcn.classList.remove('active-shareICN');
      imgShareIcn.classList.add('inactive-shareICN');    
    } else {
      imgShareIcn.classList.add('active-shareICN');
      imgShareIcn.classList.remove('inactive-shareICN');
    }
    var imgPickerResp = document.getElementsByClassName("responsive");
    for(var i = 0, length = imgPickerResp.length; i < length; i++) {   
      if( imgPickerResp[i].textContent === ''){
        imgPickerResp[i].classList.remove('selected');
        //imgTest[i].style.display = 'none';
      }     
    }
    var imgPickThumb = document.getElementsByClassName("thumbnail");
    for(var i = 0, length = imgPickThumb.length; i < length; i++) {   
      if( imgPickThumb[i].textContent === ''){
        imgPickThumb[i].classList.remove('selected');
        //imgTest[i].style.display = 'none';
      }     
    }
    //var productList = document.querySelector('#selectWrapperImage .image_picker .responsive.selected').children;
    //console.log('thumbs: ', productList);
  }
function tabNavigation(e) {
    var clickedTab = e.currentTarget.getAttribute('data-tab');
    var clickedContent = e.currentTarget.getAttribute('data-content');
    var clickedMobile = e.currentTarget.getAttribute('data-mob-tab');
    var currentTab = document.getElementById(clickedTab);
    var currentContent = document.getElementById(clickedContent);
  
    var tabAdd = document.getElementById('addTab');
    var cAdd = document.getElementById('additional-content');
  
    if (!currentTab.classList.contains('active')) {
        var homeContent = document.getElementById('content-home');
        var locationPopup = document.getElementsByClassName('locationPopup');
        if (clickedContent != 'additional-content') {
          if (clickedContent !== 'content-home') {
            homeContent.classList.remove('active');
            for (var i = 0; i < locationPopup.length; i++) {
                locationPopup[i].classList.remove('active');
                locationPopup[i].classList.remove('methodActive');
                locationPopup[i].classList.remove('locationActive');
            }
          }else {
            homeContent.classList.add('active');
            for (var i = 0; i < locationPopup.length; i++) {
                locationPopup[i].classList.remove('active');
                locationPopup[i].classList.remove('methodActive');
                locationPopup[i].classList.remove('locationActive');
            }
          }
        
        
          var tabContents = document.getElementsByClassName('secondaryTab');
          for (var i = 0; i < tabContents.length; i++) {
              if (tabContents[i].id !== clickedContent) {
                  tabContents[i].classList.remove('active');
              } else {
                  tabContents[i].classList.add('active');
              }
          }
  
          var tabs = document.getElementsByClassName('tab');
          for (var i = 0; i < tabs.length; i++) {
              if (tabs[i].id !== clickedTab) {
                  tabs[i].classList.remove('active');
              } else {
                  tabs[i].classList.add('active');
              }
          }
          cAdd.classList.remove('expand')
        }
        
  
        if (clickedMobile == 'true') {
            tabAdd.classList.add('active')
        } else if (clickedTab !== 'tabAdd') {
            tabAdd.classList.remove('active')
        }
        /*const galleryVideo = document.getElementsByClassName('galleryVideo');
        Array.prototype.forEach.call(galleryVideo, function(videoP) {
        videoP.pause();
        });
        window.scrollTo(0, 0);
        var tabsContainer = document.getElementById("tabsContainer");
        tabsContainer.scrollTo(0, 0);
  
        //close Warning and FAQ Expands
        const warning = document.getElementById("uWarning");
        const faqs = document.getElementsByClassName('faq');
        const faqSection = document.getElementById('uFaqs');
        const faqButton = document.getElementById("faqMore");
        if (faqs !=null){
          Array.prototype.forEach.call(faqs, function(faqAll) {
            faqAll.classList.remove('active');
          });
        }
        if (warning !=null){
          warning.classList.remove('expand');
        }
        if (faqSection !=null){
          faqSection.classList.remove('expand');
        }
        if (faqButton !=null){
          faqButton.classList.remove('expand');
        }*/
    }

    if (currentTab.classList.contains('active')) {
      var homeContent = document.getElementById('content-home');
      var locationPopup = document.getElementsByClassName('locationPopup');
      if (clickedContent != 'additional-content') {
        for (var i = 0; i < locationPopup.length; i++) {
            locationPopup[i].classList.remove('active');
            locationPopup[i].classList.remove('methodActive');
            locationPopup[i].classList.remove('locationActive');
        }
      }
    }

    if (clickedContent == 'additional-content') {
      if (cAdd.classList.contains('expand')) {
          cAdd.classList.remove('expand');
      } else {
          cAdd.classList.add('expand');
      }
    }
  }
  
  function closeMenu() {
    var element = document.getElementById("additional-content");
    if (element.classList.contains('expand')) {
      element.classList.remove('expand');
    } else {
      
    }
  }
  function videoPopup() {
    var pModal = document.getElementById('popupVideo');
    pModal.classList.add('active');
  }
  function closePModal() {
    var pModal = document.getElementById('popupVideo');
    pModal.classList.remove('active');
    const galleryVideo = document.getElementsByClassName('galleryVideo');
    for (var i=0, len=galleryVideo.length|0; i<len; i=i+1|0) {
        galleryVideo[i].pause();
    }
    
  }

  function pdfOpen(e) {
    const pdfViewer = document.getElementById(e.currentTarget.value);
    if (pdfViewer.classList.contains('active')) {
      pdfViewer.classList.remove('active');
    } else {
      /*const pdfViewers = document.getElementsByClassName('pdfViewer');
      Array.prototype.forEach.call(pdfViewers, function(pdfAll) {
        pdfAll.classList.remove('active');
      });*/
      pdfViewer.classList.add('active');
    }
  }
  function pdfClose(e) {
    const pdfViewer = document.getElementById(e.currentTarget.value);
    if (pdfViewer.classList.contains('active')) {
      pdfViewer.classList.remove('active');
    }
  }
  
  class Pdfviewer extends Component {
    state = { numPages: null, pageNumber: 1, scaleControl: .75};
  
    onDocumentLoadSuccess = ({ numPages }) => {
      this.setState({ numPages });
    };
  
    goToPrevPage = () =>
      this.setState(state => ({ pageNumber: state.pageNumber - 1 }));
    goToNextPage = () =>
      this.setState(state => ({ pageNumber: state.pageNumber + 1 }));
    zoomIn = () =>
      this.setState(state => ({ scaleControl: state.scaleControl + .5 }));
    zoomOut = () =>
      this.setState(state => ({ scaleControl: state.scaleControl - .5 }));
    zoomReset = () =>
      this.setState(state => ({ scaleControl: 1 }));
    render() {
      const { pageNumber, numPages, scaleControl } = this.state;
      var intFrameHeight = window.innerHeight;
      var intFrameWidth = window.innerWidth;
      if (intFrameWidth > intFrameHeight) {
        intFrameWidth = intFrameHeight / 1.2 - 75;
      }
      if (intFrameHeight > intFrameWidth) {
        intFrameHeight = intFrameWidth / 1.1 - 75;
        intFrameWidth = intFrameWidth / 1.1 - 75;
      }
      return (
        <div id={this.props.id} className={this.props.className}>
          
          <button value={this.props.id} className="pdfClose" onClick={pdfClose}></button>
          <button value={this.props.id} className="pdfCloseX" onClick={pdfClose}></button>
          <div className="pdfBox">
            <Document
              file={this.props.file}
              onLoadSuccess={this.onDocumentLoadSuccess}
              height={intFrameHeight}
            >
              <Page pageNumber={pageNumber} scale={scaleControl} width={intFrameWidth}/>
            </Document>
          </div>
          <nav>
            {pageNumber > 1 &&
              <button className="prevArrow" onClick={this.goToPrevPage}></button>
            }
            {pageNumber <= 1 &&
              <button className="prevArrow disabled"></button>
            }
            <div className="pageNumbers">{pageNumber}/{numPages}</div>
            {pageNumber < numPages &&
              <button className="nextArrow" onClick={this.goToNextPage}></button>
            }
            {pageNumber >= numPages &&
              <button className="disabled nextArrow"></button>
            }
            {scaleControl > .5 &&
              <button className="zoomIcon" onClick={this.zoomOut}><Icon>zoom_out</Icon></button>
            }
            {scaleControl <= .5 &&
              <button className="zoomIcon disabled"><Icon>zoom_out</Icon></button>
            }
            {scaleControl <= 3 &&
              <button className="zoomIcon" onClick={this.zoomIn}><Icon>zoom_in</Icon></button>
            }
            {scaleControl > 3 &&
              <button className="zoomIcon disabled"><Icon>zoom_in</Icon></button>
            }
          </nav>
        </div>
      );
    }
  }
  
  function openShareDialog(e) {
    var shareDialog = document.getElementById(e.currentTarget.value);
    var shareTitle = e.currentTarget.title;
    var shareFile = e.currentTarget.getAttribute('data-file');
    e.preventDefault();
    //shareDialog.classList.add('is-open');
    console.log('The link was clicked.');
    if (navigator.share) {
        navigator.share({
          title: shareTitle,
          url: shareFile
        }).then(() => {
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
      } else {
        shareDialog.classList.add('is-open');
      }
  }
  
  function closeShareDialog(e) {
    var shareDialog = document.getElementById(e.currentTarget.value);
    e.preventDefault();
    shareDialog.classList.remove('is-open');
    console.log('The link was clicked.');
  }
  
  function openShareDialogSelectImages(e) {
    var shareDialog = document.getElementById(e.currentTarget.value);
    var shareTitle = e.currentTarget.title;
    var shareFile = e.currentTarget.getAttribute('data-file');
    
    //const resultPara = document.querySelector('.shareSuccess');
    e.preventDefault();
    //shareDialog.classList.add('is-open');
    console.log('The link was clicked.');
    if (navigator.share) {
        navigator.share({
          title: shareTitle,
          text: shareFile
        }).then(() => {
          //resultPara.textContent = 'Shared successfully'
          console.log('Thanks for sharing!');
        })
        .catch(console.error);
      } else {
        shareDialog.classList.add('is-open');
      }
  }
  
  function closeShareDialogSelectImages(e) {
    var shareDialog = document.getElementById(e.currentTarget.value);
    e.preventDefault();
    shareDialog.classList.remove('is-open');
    console.log('The link was clicked.');
  }
  
  function locationOpen(e) {
    var locationPopup = e.currentTarget.getAttribute('data-location');
    var locationPopup = document.getElementById(locationPopup);
   
    e.preventDefault();
    locationPopup.classList.add('active');
    locationPopup.classList.add('locationActive');
    
  }
  function locationSwitch(e) {
    var locationPopup = e.currentTarget.getAttribute('data-location');
    var locationPopup = document.getElementById(locationPopup);
    var locationsTab = document.getElementById('locationsTab');
    var methodsTab = document.getElementById('methodsTab');
    var contentLocations = document.getElementById('content-locations');
    var contentMethods = document.getElementById('content-methods');
    e.preventDefault();
    locationPopup.classList.remove('methodActive');
    locationPopup.classList.add('locationActive');
    methodsTab.classList.remove('active');
    locationsTab.classList.add('active');
    contentMethods.classList.remove('active');
    contentLocations.classList.add('active');
  }
  function methodSwitch(e) {
    var locationPopup = e.currentTarget.getAttribute('data-location');
    var locationPopup = document.getElementById(locationPopup);
    var locationsTab = document.getElementById('locationsTab');
    var methodsTab = document.getElementById('methodsTab');
    var contentLocations = document.getElementById('content-locations');
    var contentMethods = document.getElementById('content-methods');
    e.preventDefault();
    locationPopup.classList.add('methodActive');
    locationPopup.classList.remove('locationActive');
    methodsTab.classList.add('active');
    locationsTab.classList.remove('active');
    contentMethods.classList.add('active');
    contentLocations.classList.remove('active');
  }
  function methodOpen(e) {
    var locationPopup = e.currentTarget.getAttribute('data-location');
    var locationPopup = document.getElementById(locationPopup);
   
    e.preventDefault();
    locationPopup.classList.add('active');
    locationPopup.classList.add('methodActive');
    
}

function closeLocation(e) {
    var locationPopup = e.currentTarget.getAttribute('data-close');
    var locationPopup = document.getElementById(locationPopup);
    e.preventDefault();
    locationPopup.classList.remove('methodActive');
    locationPopup.classList.remove('locationActive');
    locationPopup.classList.remove('active');
  }

function saveAsPDF(content) {
    // fake server request, getting the file url as response
    var dloFile = content.currentTarget.getAttribute('data-file');
    var dloServer = 'http://files.sales-app.cmps.emfluence.com/';
    if (window.matchMedia('(display-mode: standalone)').matches)  {
      var windowReference = window.open();
      windowReference.location = dloServer+dloFile;
    }
    else {
      window.location.href = dloFile;
    }
  }


const PageTemplate = ({ data }) => (
  <Layout>
    <SEO
      title={data.wordpressPage.title}
      description={data.wordpressPage.excerpt}
    />
    <Helmet>
      <meta name="format-detection" content="telephone=no" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no" />
      <body className="company" />
    </Helmet>
    <header className="mainHead flexContainer faCenter fjCenter" css={css`
       background: #000;
    `}>
    <div className="fCol12 menuCtrl">
      <AuthUserContext.Consumer>
        {authUser =>
          authUser ? (
            <Link className="headerTitle" to={ROUTES.LANDING}>   
              <span className="arrowbk"><Icon>arrow_back</Icon></span> Home         
            </Link>
          ) : (
            <Link className="headerTitle" to={ROUTES.GUEST}>  
              <span className="arrowbk"><Icon>arrow_back</Icon></span> Home          
            </Link>
          )
        }
      </AuthUserContext.Consumer>
      {/* Nav Side */}
      <Navlinks />
    </div>
    </header>
      <div id="tabsContainer" className="tabsContainer" css={css`margin-top:5vh;`}>

        <section id="content-home" className="content-home active">
            <div className="flexContainer hero">
                {data.wordpressPage.acf.company_home.hero_image !=null &&
                    <div id="heroImage" css={css`
                    background: url(${data.wordpressPage.acf.company_home.hero_image.localFile.publicURL});
                `}/>
                }
                <div className="companyLogo">
                    {data.wordpressPage.acf.company_home.logo != null &&
                        <img src={data.wordpressPage.acf.company_home.logo.localFile.publicURL}/>
                    }
                </div>
            </div>
            <div id="corePurpose" className="flexContainer faStretch maxTablet">
                <div className="fCol12">
                    <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.cp_header}}/>
                    <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.cp_content}}/>
                </div>
            </div>
            <div id="companyInfoContainer" className="flexContainer faStretch maxTablet">
                <div id="ciLeft" className="fCol12 fCol6-sm">
                    <div id="companyVideo" className="flexContainer faStretch fCol12">
                        <div className="videoPlay" onClick={videoPopup}>
                            <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.video_play_text}}/>
                        </div>
                        <div className="videoThumbnail">
                            {data.wordpressPage.acf.company_home.video_thumbnail != null &&
                            <button css={css`
                                background: url(${data.wordpressPage.acf.company_home.video_thumbnail.localFile.publicURL});
                            `} onClick={videoPopup}/>
                            }
                            {data.wordpressPage.acf.company_home.video_thumbnail === null &&
                            <button />
                            }
                        </div>
                    </div>
                    <div id="companyWWD">
                        <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.wwd_header}}/>
                        <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.wwd_content}}/>
                    </div>
                </div>
                <div id="ciRight" className="fCol12 fCol6-sm">
                    <h3 dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.cr_header}}/>
                    <div dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_home.cr_content}}/>
                </div>
            </div>
            <div className="hide">
                {carouselSlidesData.length = 0}
            </div>
            {data.wordpressPage.acf.company_home.video_repeater !=null && data.wordpressPage.acf.company_home.video_repeater.map(video => (
                <React.Fragment>  
                  {video.video_select_path && (
                      <div className="hide">{carouselSlidesData.push({"title": video.video_headline, "url": video.video_select_path, "poster": video.video_poster.localFile.publicURL,  "siteURL": data.site.siteMetadata.siteUr})}</div>
                  )}
                </React.Fragment>  
            ))}
            <div id="popupVideo" className="popupVideo">
                <button className="videoClose" onClick={closePModal}><span></span></button>
                <div className="videoContain">
                    <VideoCarousel className={carouselSlidesData.length} slides={carouselSlidesData} />
                </div>
            </div>
        </section>
        <section id="content-locations" className="content-locations secondaryTab companyLanding" css={css`background: url(${data.wordpressPage.acf.locations_home_background.localFile.publicURL});`}>
            <div className="locationsContent flexContainer faStretch maxTablet" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.locations_home_content}}/>
            {data.wordpressPage.acf.company_locations !='' && data.wordpressPage.acf.company_locations.map((location, index) => (
                <React.Fragment>  
                    <button className="locationName" data-location={'l'+index} dangerouslySetInnerHTML={{ __html: location.location_name}} onClick={locationOpen}/>
                </React.Fragment> 
            ))}
        </section>
        <section id="content-methods" className="content-methods secondaryTab companyLanding" css={css`background: url(${data.wordpressPage.acf.methods_home_background.localFile.publicURL});`}>
            <div className="methodsContent flexContainer faStretch maxTablet" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.methods_home_content}}/>
            {data.wordpressPage.acf.company_locations !='' && data.wordpressPage.acf.company_locations.map((location, index) => (
                <React.Fragment>  
                    {location.methods_enable !=false &&
                        <button className="locationName" data-location={'l'+index} dangerouslySetInnerHTML={{ __html: location.location_name}} onClick={methodOpen}/>
                    }
                </React.Fragment>  
            ))}
        </section>
        <section id="content-brands" className="content-brands secondaryTab">
            <Online>
              <div className="brandsContent flexContainer faStretch maxTablet" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_brands_content}}/>
            </Online>
            <Offline>
              <div className="brandsContent flexContainer faStretch maxTablet offlineOurBrands" dangerouslySetInnerHTML={{ __html: data.wordpressPage.acf.company_brands_content}}/>
            </Offline>
            <div id="companyBrandLogos" className="flexContainer faCenter maxTablet">
              {data.wordpressPage.acf.company_logo_sections !='' && data.wordpressPage.acf.company_logo_sections.map((logoSection, lIndex) => (
                <React.Fragment>
                  <h3 dangerouslySetInnerHTML={{ __html: logoSection.section_header}}/>
                  {logoSection.company_brand_logos !='' && logoSection.company_brand_logos.map((logoItem, index) => (
                      <React.Fragment>
                        {logoItem.link != '' &&
                          logoItem.logo.localFile && (
                            <React.Fragment>
                              <Online>
                                <a href={logoItem.link} target="_blank">
                                  <img src={logoItem.logo.localFile.publicURL} alt={logoItem.description}/>
                                </a>
                              </Online>
                              <Offline>
                                <a>
                                  <img src={logoItem.logo.localFile.publicURL} alt={logoItem.description}/>
                                </a>
                              </Offline>
                            </React.Fragment>
                          )
                        }
                        {logoItem.link == '' &&
                          logoItem.logo.localFile && (
                            <img src={logoItem.logo.localFile.publicURL} alt={logoItem.description}/>
                          )
                        }
                      </React.Fragment>  
                  ))}
                </React.Fragment>
              ))}
            </div>
        </section>
        <section id="content-resources" className="content-resources secondaryTab">
        <div className="fCol12 brandName" css={css` color: #00ACED; `}>
            <div className="subHeading">
              <h1>Resources</h1>
            </div>
        </div>
        {data.wordpressPage.acf.company_add_resource != null && data.wordpressPage.acf.company_add_resource.map((resource, index) => (
          <div>
            <React.Fragment>
              <div className="grayBorderBottom">
                <div className="flexContainer maxTablet">
                  <div className="fCol12 flexContainer faCenter">
                    <div className="dIcon">
                    {resource.resource_upload != null &&
                      <button value={'pdfViewer'+index} /*cUrl={resource.prod_upload_collateral.url.localFile.publicURL}*/ onClick={ pdfOpen }>
                        <Img className=""
                          sizes={resource.resource_thumbnail.localFile.childImageSharp.sizes}
                        />
                            VIEW        
                      </button>
                    }
                    </div>
                    <div className='dName' dangerouslySetInnerHTML={{ __html: resource.resource_name_1+'<br/>'+resource.resource_name_2}}></div>
                    <div className='dButtons'>
                      <Online>
                      {resource.resource_order !== "" &&
                        <Link target="_blank" to={resource.resource_order}>
                          <button className="grayButton">Order</button>              
                        </Link>
                      }
                      </Online>
                      <Offline>
                        {resource.resource_order !== "" &&
                          <a className="offlinebtns">
                            <button type="button" className="grayButton disabled orderD">Order</button>  
                          </a>
                        }
                      </Offline>

                      <Online>
                        {resource.resource_upload.url.localFile  && (
                        <Link>
                          <button value={'shareDialog'+index} id={'deviceShare'+index} type="button" className="grayButton" title={'Share '+resource.resource_name_1} data-file={webURL+resource.resource_upload.url.localFile.localURL} onClick={openShareDialog}>Share
                          </button>            
                        </Link>
                        )}
                      </Online>
                      <Offline>
                        <a className="offlinebtns">
                          <button type="button" className="grayButton disabled">Share </button>  
                        </a>
                      </Offline>
                      <Online>
                        {resource.resource_zip_download.url.localFile  && (
                          <a onClick={saveAsPDF} data-file={resource.resource_zip_download.url.localFile.localURL}><button  className="grayButton">Download</button></a>
                        )}
                      </Online>
                      
                       <Offline>
                        <a className="offlinebtns">
                          <button type="button" className="grayButton disabled dloOF">Download </button>  
                        </a>
                      </Offline>

                      </div>
                  </div>
                </div>
              </div>
              {resource.resource_upload.url.localFile  && (
                <Pdfviewer id={'pdfViewer'+index} className="pdfViewer" file={resource.resource_upload.url.localFile.localURL}/>
              )}
              {resource.resource_upload.url.localFile  && (
              <div id={'shareDialog'+index} className="share-dialog">
                <button value={'shareDialog'+index} onClick={closeShareDialog} className="boxClose"></button>
                <div className="inner">
                  <header>
                    <h3 className="dialog-title">Share {resource.resource_name_1}</h3>
                  </header>
                  <button value={'shareDialog'+index} className="close-button" onClick={closeShareDialog}></button>
                  <div className="targets">
                    <a className="button" href={'mailto:?body='+data.site.siteMetadata.siteUrl+resource.resource_upload.url.localFile.localURL}>
                      <svg>
                        <use href="#email"></use>
                      </svg>
                      <span>Email</span>
                    </a>
                  </div>
                </div>
              </div>
              )}
            </React.Fragment>    
          </div>
        ))}
        </section>
        <div className="hide">
          {images.length = 0}
        </div>
        {data.wordpressPage.acf.company_locations !=null && data.wordpressPage.acf.company_locations.map((location, index) => (
            <React.Fragment>
                <div className="hide">
                  {images.locations.push({"location": location.location_name,"locationID": index,"photos": [] })}
                </div>
                <div id={'l'+index} className="locationPopup imageCount">
                    <div className="locationBar">
                        <span className="locationName" dangerouslySetInnerHTML={{ __html: location.location_name}}/>
                        <button data-close={'l'+index} className="closeButton" onClick={closeLocation}/>
                    </div>
                    {location.methods_enable !=false &&
                        <div className="locationTabs">
                            <button className="lButton" data-location={'l'+index} onClick={locationSwitch}>Locations</button>
                            <button className="mButton" data-location={'l'+index} onClick={methodSwitch}>Methods</button>
                        </div>
                    }
                    
                    <div className="locationsContent flexContainer faStretch maxTablet">
                        {location.location_repeater !=null && location.location_repeater.map(singleLocation => (
                            <React.Fragment>
                                <div className="singleLocationContent" dangerouslySetInnerHTML={{ __html: singleLocation.location_description}}/>
                                <ul className="addressList">
                                {singleLocation.address_repeater !=null && singleLocation.address_repeater.map(address => (
                                    <React.Fragment>
                                        <li className="address">
                                            <a href={address.map_link}>
                                                <div dangerouslySetInnerHTML={{ __html: address.address}}/>
                                            </a>
                                            <a href={'tel:'+address.phone.replace(/\./g,'')}>
                                                <div dangerouslySetInnerHTML={{ __html: address.phone}}/>
                                            </a>
                                        </li>
                                    </React.Fragment>  
                                ))}
                                </ul>
                            </React.Fragment>  
                        ))}
                        {location.location_map != null &&
                          <img className="locationsMap" src={location.location_map.localFile.publicURL}/>
                        }
                        <div className="locationGallery">
                          {location.location_gallery != null && 
                          

                          <React.Fragment>
                            <div className="hide">
                              {images.locations[index].photos.length = 0}
                            </div>
                            {location.location_gallery.map(locationGallery => (
                              <React.Fragment>
                                  <div className="hide">
                                    {locationGallery.image.localFile && (
                                      <React.Fragment>
                                        {images.locations[index].photos.push({"lbImage": locationGallery.image.localFile.publicURL,"lbCaption": locationGallery.description.replace(/(<([^>]+)>)/ig,"").replace("&#8217;", "'")})}
                                      </React.Fragment>
                                    )}
                                  </div>
                              </React.Fragment>  
                            ))}
                            <div id="lightboxWrapper" className="lightboxWrapper fCol12 active-selects">
                              <LightboxGallery key={'locationLightbox'+index} locationIndex={index} imageCount={location.location_gallery.length} />
                            </div>
                          </React.Fragment>
                          }
                        </div>
                    </div>
                    {location.methods_enable !=false &&
                        <div className="methodsContent flexContainer faStretch maxTablet">
                            <div className="singleMethodContent" dangerouslySetInnerHTML={{ __html: location.methods_content}}/>
                            {location.methods_table.column_one_headline !='' && location.methods_table.column_one_content !='' &&
                            <div className="singleMethodColumns">
                                {location.methods_table.column_one_content !=null &&
                                    <div className="methodColumn fCol12 fCol6-sm">
                                        <h3 dangerouslySetInnerHTML={{ __html: location.methods_table.column_one_headline }}/>
                                        <div dangerouslySetInnerHTML={{ __html: location.methods_table.column_one_content }}/>
                                    </div>
                                }
                                {location.methods_table.column_two_content !=null &&
                                    <div className="methodColumn fCol12 fCol6-sm">
                                        <h3 dangerouslySetInnerHTML={{ __html: location.methods_table.column_two_headline }}/>
                                        <div dangerouslySetInnerHTML={{ __html: location.methods_table.column_two_content }}/>
                                    </div>
                                }
                            </div>
                            }
                            <img className="methodsImage" src={location.methods_image.localFile.publicURL}/>
                        </div>
                    }
                </div>
            </React.Fragment>  
        ))}
    </div>
    <footer className="productTabs" css={css` border-color: #009EDD; `}>
      
        <Paper square >
          <Tabs
            variant="fullWidth"
            indicatorColor="secondary"
            textColor="secondary"
            aria-label="icon label tabs example"
          >
            <Tab id="homeTab" className="icon-brand-home active tab" label="Corporate Home" data-tab="homeTab" data-content="content-home" onClick={ tabNavigation }/>
            <Tab id="locationsTab" className="icon-Locations tab" label="Locations" data-tab="locationsTab" data-content="content-locations" onClick={ tabNavigation }/>
            <Tab id="methodsTab" className="icon-Methods tab" data-tab="methodsTab" data-content="content-methods" label="Methods" onClick={ tabNavigation }/>
            <Tab id="brandsTab" className="icon-OurSaltBrands tab" label="Our Salt Brands" data-tab="brandsTab" data-content="content-brands" onClick={ tabNavigation }/>
            <Tab id="resourcesTab" className="icon-Resources tab" label="Resources" data-tab="resourcesTab" data-content="content-resources" onClick={ tabNavigation }/>
            <Tab id="addTab" className="icon-additional tab" label="Additional" data-tab="addTab" data-content="additional-content" onClick={ tabNavigation }/>
          </Tabs>
        </Paper>
        <div id="additional-content">
          <Tabs>
            <Tab id="methodsTab2" className="icon-Methods tab" label="Methods" data-tab="methodsTab2" data-content="content-methods" onClick={ tabNavigation }/>
            <Tab id="brandsTab2" className="icon-OurSaltBrands tab" label="Our Salt Brands" data-tab="brandsTab2" data-content="content-brands" onClick={ tabNavigation }/>
            <Tab id="resourcesTab2" className="icon-Resources tab" label="Resources" data-tab="resourcesTab2" data-content="content-resources" onClick={ tabNavigation }/>
          </Tabs>
          <button id="addMenuClose" onClick={ closeMenu }><Icon>close</Icon></button>
        </div>
      </footer>
  </Layout>
)

export default PageTemplate

export const query = graphql`
  query($id: Int!) {
    site {
      siteMetadata {
        siteUrl
        title
        menuLinks {
          link
          name
        }
      }
    }
    wordpressPage(wordpress_id: { eq: $id }) {
      title
      excerpt
      content
      parent_element {
        title
        slug
      }
      acf {
        company_home {
            cr_header
            cr_content
            cp_header
            cp_content
            wwd_header
            wwd_content
            video_play_text
            video_thumbnail {
                localFile {
                    publicURL
                    childImageSharp {
                        sizes(maxWidth:1200) {
                            ...GatsbyImageSharpSizes
                        }
                    }
                }
            }
            video_repeater {
                video_headline
                video_select_path
                video_select {
                    source_url
                    localFile {
                      localURL
                    }
                }
                video_poster {
                    localFile {
                        publicURL
                        childImageSharp {
                            sizes(maxWidth:1200) {
                                ...GatsbyImageSharpSizes
                            }
                        }
                    }
                }
            }
            logo {
                localFile {
                    publicURL
                    childImageSharp {
                        sizes(maxWidth:1200) {
                            ...GatsbyImageSharpSizes
                        }
                    }
                }
            }
            
            hero_image {
                localFile {
                    publicURL
                    childImageSharp {
                        sizes(maxWidth:1200) {
                            ...GatsbyImageSharpSizes
                        }
                    }
                }
            }
        }
        company_locations {
            location_name
            location_repeater {
              address_repeater {
                address
                map_link
                phone
              }
              location_description
            }
            methods_content
            methods_image {
              localFile {
                publicURL
              }
            }
            methods_table {
              column_one_content
              column_one_headline
              column_two_content
              column_two_headline
            }
            location_map {
              localFile {
                publicURL
              }
            }
            location_gallery {
              description
              image {
                localFile {
                  publicURL
                }
              }
            }
            methods_enable
          }
          locations_home_background {
            localFile {
              publicURL
            }
          }
          locations_home_content
          methods_home_background {
            localFile {
              publicURL
            }
          }
          methods_home_content
          company_brands_content
          company_logo_sections {
            section_header
            company_brand_logos {
                description
                link
                logo {
                  localFile {
                      publicURL
                  }
                }
            }
          }
        company_add_resource {
            resource_name_1
            resource_name_2
            resource_order
            resource_thumbnail {
                localFile {
                    childImageSharp {
                        sizes(maxWidth:1200) {
                            ...GatsbyImageSharpSizes
                        }
                    }
                }
            }
            resource_upload {
                link
                url {
                    localFile {
                      localURL
                    }
                }
            }
            resource_zip_download {
              filename
              url {
                localFile {
                  localURL
                  url
                  originalSourceUrl
                }
              }
          }
        }
      }
    }
  }
`